import React from "react"
import Title from "./Title"
import why from "../constants/why"
import { Link } from "gatsby"
const Whyus = () => {
  return (
    <section className="section bg-grey">
      <Title title="why us" />
      <div className="section-center services-center">
        {why.map(service => {
          const { id, icon, title, text } = service
          return (
            <article key={id} className="service">
              {icon}
              <h4>{title}</h4>
              <div className="underline"></div>
              <p>{text}</p>
            </article>
          )
        })}
      </div>
      <Link to="/about" className="btn center-btn">
        more info
      </Link>
    </section>
  )
}

export default Whyus
