import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Whyus from "../components/Whyus"
import Jobs from "../components/Jobs"
import Projects from "../components/Projects"
import Seo from "../components/Seo"
import Expertises from "../components/Expertises"
const IndexPage = ({ data }) => {
  const {
    allStrapiService: { nodes: services },
  } = data
  return (
    // <>
    //   <main>
    <Layout>
      <Seo title="Home" desscription="This is our Homepage" />
      <Hero />
      {/* <Services /> */}
      <Whyus />
      {/* <Jobs /> */}
      <Expertises />
      {/* <Projects title="featured projects" showLink projects={projects} /> */}
      <Services title="featured services" showLink services={services} />
    </Layout>
    //   </main>
    // </>
  )
}

export const query = graphql`
  {
    allStrapiService(filter: { feature: { eq: true } }) {
      nodes {
        description
        feature
        icon
        id
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        slug
        speciality {
          detail
          id
        }
        title
      }
    }
  }
`

export default IndexPage
