import React from "react"
import Title from "./Title"
import { FaAngleDoubleRight } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"

const query = graphql`
  {
    allStrapiExpert {
      nodes {
        subject
        focus
        description {
          detail
          id
        }
        strapiId
      }
    }
  }
`

const Expertises = () => {
  const data = useStaticQuery(query)
  console.log(data)
  const {
    allStrapiExpert: { nodes: experts },
  } = data
  console.log(experts)
  const [value, setValue] = React.useState(0)
  const { subject, focus, description } = experts[value]
  console.log(subject, focus, description)

  return (
    <section className="section jobs">
      <Title title="expertise" />
      <div className="jobs-center">
        {/* btn container */}
        <div className="btn-container">
          {experts.map((expert, index) => {
            return (
              <button
                key={index}
                className={index === value ? "job-btn active-btn" : "job-btn"}
                onClick={() => setValue(index)}
              >
                {expert.subject}
              </button>
            )
          })}
        </div>
        {/* expert info */}
        <article className="job-info">
          <h3>{focus}</h3>
          <h4>{subject}</h4>
          {/* <p className="job-date">{date}</p> */}
          {description.map(item => {
            return (
              <div key={item.id} className="job-desc">
                <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                <p>{item.detail}</p>
              </div>
            )
          })}
        </article>
      </div>
      <Link to="/services" className="btn center-btn">
        more info
      </Link>
    </section>
  )
}

export default Expertises
