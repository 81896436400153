import React from "react"
import {
  FaCode,
  FaCodeBranch,
  FaSketch,
  FaUsers,
  FaAndroid,
  FaDocker,
  FaJenkins,
  FaServer,
  FaRobot,
  FaGraduationCap,
} from "react-icons/fa"
const why = [
  {
    id: 1,
    icon: <FaUsers className="service-icon" />,
    title: "people",
    text: `We are a passionate team of engineers with decades of experience, working in a vast range of industries in all aspects of the software development lifecycle and what unites us, is a huge belief in the power of collaboration and a passion of problems solving.`,
    // text: `Our Dev Teams uses their knowledge of the latest open-source development frameworks and technologies, such as React, NextJS, Flask, Django, to provide secure, dependable and scalable IT Solutions.`,
  },
  {
    id: 2,
    icon: <FaSketch className="service-icon" />,
    title: "open-source",
    text: `Open Source has changed the way software development teams build software. It is everywhere and it is leading to innovation and accelerating software development pace. We are passionate about open-source technologies and they form an essential part of our growing business. We aim to share the wide variety of benefits with our Clients, including improved costs, greater flexibility, security and accountability.`,
    // text: `We provide consulting services to help our clients navigate the ever-changing world of new technologies in order to facilitate the development and implementation of secure IT Solutions.`,
  },
  {
    id: 3,
    icon: <FaGraduationCap className="service-icon" />,
    title: "digital transformation",
    text: `Digital transformation is not a one-and-done process but rather a continuous set of changes allowing individuals and teams to improve their performance. Our approach to digital transformation puts people at the center, ensuring that innovative ideas are effectively implemented and adopted throughout your organization.`,
    // text: `We work closely with our clients to identify thier immidiate or upcoming resources needs and presents them vetted, quality resources that best fit thier coorporate culture. `,
  },
  // {
  //   id: 4,
  //   icon: <FaDocker className="service-icon" />,
  //   title: "platform support",
  //   text: `Our team of platform experts uses their extensive knowledge of the latest Open-Source technologies, such as Docker and Kubernetes, to esnure our customers services availability and scalablility on demand.`,
  // },
  // {
  //   id: 5,
  //   icon: <FaRobot className="service-icon" />,
  //   title: "automation  enablement",
  //   text: `Enabling our customers to achieve their targeted speed of product deleivery through a rigorous implemnetation of automations and automated processes accros the various aspects of the SDLC.`,
  // },
  // {
  //   id: 6,
  //   icon: <FaGraduationCap className="service-icon" />,
  //   title: "digital university ",
  //   text: `We beleive in Continious Learning to accelerate digital transformation. Through our Digital University we've designed tranings to equip our hypernot's with the technical skills needed to perpetuate the innovation cycle.`,
  // },
]

// const links = data.map(link => {
//   return (
//     <li key={link.id}>
//       <a href={link.url} className="social-link">
//         {link.icon}
//       </a>
//     </li>
//   )
// })

// export default ({ styleClass }) => {
//   return (
//     <ul className={`social-links ${styleClass ? styleClass : ""}`}>{links}</ul>
//   )
// }

export default why
